import {ContentfulImage} from "collections/contentrenderer/ContentfulImage";
import {HiFlex} from "components/flex/core";
import {HiGrid} from "components/grid/core";
import {HiHeader} from "components/header/core";
import {HiSpace} from "components/space/core";
import {HiText} from "components/text/core";
import dayjs from "dayjs";
import {Link} from "gatsby";
import * as React from "react";
import "./BlogPostCard.scss";

export default function BlogPostCard(
  {
    authors,
    id,
    bodyRichText,
    slug,
    title,
    synopsis,
    pinned,
    publishDate,
    category,
    heroImage,
    body
  }
) {

  const gridProps = {
    col: [{min: 12}],
    gap: pinned ? [{min: 8}] : [{min: 4}],
  }

  const titleGridProps = {
    colspan: pinned ? [{min: 12}, {md: 6}] : [{min: 12}],
    rowstart: pinned ? [{min: 2}, {md: 1}] : [{min: 2}],
  }

  const imageGridProps = {
    colspan: pinned ? [{min: 12}, {md: 6}] : [{min: 12}],
    rowstart: pinned ? [{min: 1}] : [{min: 1}],
  }

  const blogCategoryProps = {
    color: "brand",
    size: pinned ? "tiny" : "mini",
    variant: "secondary",
    weight: "normal",
    addClassNames: "mb-2"
  }

  // let readingTimeOfBodyRichText = readingTime(
  //   bodyRichText?.raw ?
  //   documentToPlainTextString(JSON.parse(bodyRichText.raw))
  //                     : ""
  // );

  const formattedPublishDate = dayjs(publishDate).format('MMM D YYYY')

  return (
    pinned ? (
      <div className="blog-card">
        <HiGrid {...gridProps}>
          <HiGrid.Item {...imageGridProps}>
            {heroImage && (<ContentfulImage image={heroImage} width="100%" />)}
          </HiGrid.Item>
          <HiGrid.Item {...titleGridProps}>
            <Link className="blog-link" to={`/blog/${slug}/`}>
              {category && <HiHeader {...blogCategoryProps}>{category?.title}</HiHeader>}
              <HiHeader element="h1" variant="primary" size="large" weight="black" addClassNames="mb-8">{title}</HiHeader>
                <>
                  <HiText size="large">{synopsis?.internal.content}</HiText>
                </>
              {/*<HiText>{publishDate}</HiText>*/}
            </Link>
              <HiSpace alignItems={[{min: "center"}]} size={[{min: "small"}]} addClassNames="mt-6">
                <HiText>By</HiText>
                <Authors authors={authors} size="medium" />
                <HiText>On</HiText>
                <HiText size="medium" variant="primary" weight="normal">{formattedPublishDate}</HiText>
              </HiSpace>
          </HiGrid.Item>
        </HiGrid>
      </div>
    ) : (
      <div className="blog-card">
        <HiGrid {...gridProps}>
          <HiGrid.Item {...imageGridProps}>
            {heroImage && (<ContentfulImage image={heroImage} width="100%" />)}
          </HiGrid.Item>
          <HiGrid.Item {...titleGridProps}>
            <Link className="blog-link" to={`/blog/${slug}/`}>
              <HiFlex spacing={[{min: "tiny"}]} addClassNames="mb-3">
                <HiFlex.Item>
                  <Authors authors={authors} size="small" />
                </HiFlex.Item>
                {category && (
                  <>
                    <HiFlex.Item><HiText size="small" variant="secondary"> in </HiText></HiFlex.Item>
                    <HiFlex.Item><HiText size="small" variant="primary" weight="normal">{category?.title}</HiText></HiFlex.Item>
                  </>
                )}
              </HiFlex>
              <HiHeader element="h2" variant="secondary" size="tiny" weight="bold">{title}</HiHeader>
              {/*<HiText>{publishDate}</HiText>*/}
            </Link>
             <HiSpace size={[{min: "small"}]} addClassNames="mt-3">
               <HiText weight="normal" size="tiny" variant="secondary">{formattedPublishDate}</HiText>
               {/*<HiText weight="normal" size="tiny" variant="secondary">·</HiText>*/}
               {/*<HiText weight="normal" size="tiny" variant="secondary">{readingTimeOfBodyRichText.text}</HiText>*/}
             </HiSpace>
          </HiGrid.Item>
        </HiGrid>
      </div>
    )
  )

}

function Authors({authors, size}) {

  return (
    authors ? (
      authors.map((author, i) => (
        <HiFlex.Item key={`author-${i}-${author.contentful_id}`}>
          <HiText size={size} variant="primary" weight="normal">{author.name}</HiText>
        </HiFlex.Item>
      ))
    ) : (
      <HiFlex.Item>
        <HiText size={size} variant="primary" weight="normal">Navatom Content Team</HiText>
      </HiFlex.Item>
    )
  )
}